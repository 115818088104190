import Network from '@/services/network';
import {ToWords} from "to-words";

export default function handleRequisitions() {
    const network = new Network;

    const fetchRequisitionNumber = query => {
        return network.api('get', '/procurement/requisition/generate-requisition' + query);
    }

    const createNewRequisition = (data, query) => {
        return network.api('post', '/procurement/requisition'+query, data);
    }

    const fetchRequisitionList = (query) => {
        return network.api('get', '/procurement/requisition' + query);
    }

    const fetchSingleRequisition = (requisitionId, query) => {
        return network.api('get', '/procurement/requisition/' + requisitionId + query);
    }

    const updatePRAllocation = (data, query) => {
        return network.api('post', '/procurement/requisition/update-pr-allocation' + query, data);
    }

    const fetchAllocatedRequisitionList = (query) => {
        return network.api('get', '/procurement/allocated-requisition-list' + query);
    }

    const fetchCSNumber = query => {
        return network.api('get', '/procurement/cs/generate-cs' + query);
    }

    const createNewCS = (data, query) => {
        return network.api('post', '/procurement/cs'+query, data);
    }

    const fetchCSList = (query) => {
        return network.api('get', '/procurement/cs' + query);
    }

    const fetchCSUsersList = (query) => {
        return network.api('get', '/procurement/cs/users' + query);
    }

    const fetchSingleCS = (csId, query) => {
        return network.api('get', '/procurement/cs/' + csId + query);
    }

    const updateSupplierCS = (data, query) => {
        return network.api('post', '/procurement/cs/update-supplier' + query, data);
    }

    const updateCS = (data,csId, query) => {
        return network.api('put', '/procurement/cs/'+ csId + query, data);
    }

    const updateCSStatus = (data,csId, query) => {
        return network.api('put', '/procurement/cs/'+ csId + '/status/update' + query, data);
    }

    const fetchApprovedCSList = (query) => {
        return network.api('get', '/procurement/approved-cs' + query);
    }

    const fetchPONumber = query => {
        return network.api('get', '/procurement/purchase-order/generate-po-number' + query);
    }

    const fetchCSGeneralData = (data, query) => {
        return network.api('post', '/procurement/purchase-order/get-cs-general-items' + query, data);
    }

    const createNewPO = (data, query) => {
        return network.api('post', '/procurement/purchase-order'+query, data);
    }

    const fetchPOList = (query) => {
        return network.api('get', '/procurement/purchase-order' + query);
    }

    const fetchSinglePO = (poId, query) => {
        return network.api('get', '/procurement/purchase-order/' + poId + query);
    }

    const fetchPONotes = (query) => {
        return network.api('get', '/procurement/requisition/get-po-notes' + query);
    }

    const updatePOItem = (data, poId, query) => {
        return network.api('put', '/procurement/purchase-order/'+ poId + query, data);
    }

    const fetchPOUsersList = (query) => {
        return network.api('get', '/procurement/purchase-order/users' + query);
    }

    const updatePOStatus = (poId, query, data) => {
        return network.api('put', '/procurement/purchase-order/'+ poId + '/status/update' + query, data);
    }

    const fetchSingleCSForPrint = (csId, query) => {
        return network.api('get', '/procurement/cs/print/' + csId + query);
    }

    const updateRequisitionData = (data, requisitionId, query) => {
        return network.api('post', '/procurement/requisition/'+ requisitionId + query, data);
    }

    const getRequisitionUserList = (query) => {
        return network.api('get', '/procurement/requisition/users' + query);
    }

    const updateRequisitionStatus = (requisitionId, query, data) => {
        return network.api('put', `/procurement/requisition/${requisitionId}/status/update${query}`, data);
    }

    const getCSAmountQuantityRemainingLimit = (csId, query) => {
        return network.api('get', '/procurement/cs/get-amount-quantity-remaining-limit/' + csId + query);
    }

    const fetchSVRequisitionList = (query) => {
        return network.api('get', '/procurement/requisition/get-sv-requisitions' + query);
    }

    const fetchRequisitionGeneralData = (data, query) => {
        return network.api('post', '/procurement/requisition/get-requisition-general-items' + query, data);
    }

    const fetchFundRequisitionNumber = query => {
        return network.api('get', '/procurement/fund-requisition/generate-fund-requisition' + query);
    }

    const createNewFundRequisition = (data, query) => {
        return network.api('post', '/procurement/fund-requisition'+query, data);
    }

    const fetchFundRequisitionList = (query) => {
        return network.api('get', '/procurement/fund-requisition' + query);
    }

    const fetchSingleFundRequisition = (requisitionId, query) => {
        return network.api('get', '/procurement/fund-requisition/' + requisitionId + query);
    }

    const updateFundRequisition = (data, requisitionId, query) => {
        return network.api('put', '/procurement/fund-requisition/'+ requisitionId + query, data);
    }

    const fetchSPRequisitionList = (query) => {
        return network.api('get', '/procurement/requisition/get-sp-requisitions' + query);
    }

    return {
        fetchRequisitionNumber,
        createNewRequisition,
        fetchRequisitionList,
        fetchSingleRequisition,
        updatePRAllocation,
        fetchAllocatedRequisitionList,
        fetchCSNumber,
        createNewCS,
        fetchCSList,
        fetchSingleCS,
        updateSupplierCS,
        updateCS,
        fetchApprovedCSList,
        fetchPONumber,
        fetchCSGeneralData,
        createNewPO,
        fetchPOList,
        fetchSinglePO,
        fetchPONotes,
        updatePOItem,
        fetchSingleCSForPrint,
        updateRequisitionData,
        getRequisitionUserList,
        updateRequisitionStatus,
        fetchCSUsersList,
        updateCSStatus,
        fetchPOUsersList,
        updatePOStatus,
        getCSAmountQuantityRemainingLimit,
        fetchSVRequisitionList,
        fetchRequisitionGeneralData,
        fetchFundRequisitionNumber,
        createNewFundRequisition,
        fetchFundRequisitionList,
        fetchSingleFundRequisition,
        fetchSPRequisitionList,
        updateFundRequisition,
    }
}
